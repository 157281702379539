import React from "react";
import { FC, useEffect, useState } from "react";
import { Room, Usuario } from "../lib/types.tsx";
import axios from "axios";
import { API_BASE_URL, tokenBearer } from "../lib/constants.js";

interface RoomListProps {
  room: Room | undefined;
  usuario: Usuario;
  isSelected: boolean;
  onClick: () => void;
}

const RoomRow: FC<RoomListProps> = ({room, usuario, isSelected, onClick}) => {
  const [userMatch, setUserMatch]=useState<Usuario>();
  const [colorSeleccionado, setColorSeleccionado] = useState<String>("#535bd4");

  
  
  useEffect(()=>{
    if(isSelected) setColorSeleccionado("white")
    if(!isSelected) setColorSeleccionado("#535bd4")
    
  }, [isSelected])
  /* 
  if(localStorage.getItem("chatSeleccionado")){
    //console.log("actual "+roomActual.id)
    if(localStorage.getItem("chatSeleccionado")==room?.id){
      console.log("seleccionada")
      setColorSeleccionado("white")
    }
    else
      console.log("no seleccionada")
  } */

  useEffect(()=>{
    axios.get(`${API_BASE_URL}/api/v1/users/${room?.destinatarioId}`,
      tokenBearer
    ).then(res =>{
      const user = res.data as Usuario;
      setUserMatch(user);
    });
  }, [room])

  return (
    <li className="list-unstyled py-2" onClick={onClick} 
      style={{background: `${colorSeleccionado}`, borderRadius: '0 5px 5px 0', display: 'flex', alignItems: 'center', cursor:'pointer'}}>
      <div className="col-md-12">
        <div className="d-flex">
          <div className="col-md-3 px-3" style={{display: "flex", alignItems: "center"}}>
            <img src={`${userMatch?.foto}`} style={{width: "60px", height: "60px",borderRadius:"50%"}} />
          </div>
          <div className="col-md-9 ps-1"  style={{alignContent:'center'}}>
            <h5 className="p-1 m-0" style={{fontSize:'16px', fontWeight:'bold', textAlign:'start'}}>{userMatch?.nombre}</h5>
            {/*<h6 className="p-1 m-0" style={{fontSize:'10px', textAlign:'start'}}>{userMatch?.correo}</h6>*/}
          </div>
        </div>
      </div>
      <hr />
    </li>
  );
};

export default RoomRow;
