import React, { useEffect, useRef } from 'react'

const LoadingForm =({cerrar})=>{
    const fadeTarget = useRef(null);

    useEffect(()=>{
        setTimeout(()=>{
            if (fadeTarget.current!==null) {
                fadeOutEffect()
                fadeTarget.current.style.display='none'
            }            
        },2000)
    }, [fadeTarget])

    useEffect(()=>{
      if(cerrar){
        setTimeout(()=>{
          if (fadeTarget.current!==null) {
              fadeOutEffect()
              fadeTarget.current.style.display='none'
          }            
        },2000)
      }
    }, [cerrar])

    function fadeOutEffect() {
        var fadeEffect = setInterval(function () {
          if (fadeTarget.current!==null){
            if (!fadeTarget.current.style.opacity) {
                fadeTarget.current.style.opacity = 1;
            }
            if (fadeTarget.current.style.opacity > 0) {
                fadeTarget.current.style.opacity -= 0.1;
            } else {
                clearInterval(fadeEffect);
            }
          }
        }, 100);
    }

    return (
        <div className="preloader" ref={fadeTarget}>
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
    )
}
export default LoadingForm