import React, { useEffect, useRef, useState } from 'react'
import {Ciudad, Pais, Profile, Usuario} from '../../lib/types';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import { API_BASE_URL, FRONT_BASE_URL, tokenBearer } from '../../lib/constants';
import "./style.css";
import Asistente from './Asistente.tsx';

const APIkey = "d06b2ec4116f4b0b931605a7e59c1ec7";

const ProfileUser=({usuario, authenticated})=>{
    const [usuarioData, setUsuarioData] = useState<Usuario>();
    const [profileData, setProfileData] = useState<Profile>();
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const location = useLocation();
    const [profileImage, setProfileImage] = useState(null);
    const [base64Image, setBase64Image] = useState('');
    const imgLoading = useRef<HTMLImageElement>(0)

    const [mostrarGifPregunta, setMostrarGifPregunta] = useState(false);
    const [mostrarOkPregunta, setMostrarOkPregunta] = useState(false);
    const [mostrarGifPerfil, setMostrarGifPerfil] = useState(false);
    const [mostrarOkPerfil, setMostrarOkPerfil] = useState(false);

    //const [paises, setPaises] = useState<Pais[]>([]);
    //const [ciudades, setCiudades] = useState<Ciudad[]>([]);

    /* const [selPais, setSelPais] = useState('');
    const [selCiudad, setSelCiudad] = useState('');
  
    useEffect(() => {
      cargarPaises();
    }, []); */

    const [locationUser, setLocationUser] = useState();

    
  
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);

          setUsuarioData((prevState) => ({
            ...prevState,
            foto: reader.result
          }))
        };
        reader.readAsDataURL(file);
      }
    };

    function getLocationInfo(latitude, longitude) {
      const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${APIkey}`;
      fetch(url).then((response) => response.json()).then((data) => {
        if (data.status.code === 200) {
          setLocationUser(data.results[0].formatted);
          const arrayLocation=data.results[0].formatted.split(",")
          if(usuarioData){
            const pais=arrayLocation[arrayLocation.length-1]
            const ciudad=arrayLocation[arrayLocation.length-2]

            setUsuarioData((prevState) => ({
              ...prevState,
              pais: pais,
              ciudad: ciudad
            }));

            console.log(usuarioData)
            imgLoading.current.style.display="none"
          }
        }
      }).catch((error) => console.error(error));
    }

    function buscarUbicacion(){
      if(imgLoading.current) imgLoading.current.style.display="block"

      if (navigator.geolocation) {
        console.log("buscando geoloca si")

        navigator.permissions.query({
          name: "geolocation"
        }).then(function(result) {
          if (result.state === "granted") { 
            navigator.geolocation.getCurrentPosition(success, errors, {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }); 
          } else if (result.state === "prompt") { 
            navigator.geolocation.getCurrentPosition(success, errors, {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }); 
          } else if (result.state === "denied") {
            if(usuarioData){
              usuarioData.ciudad="-"
              usuarioData.pais="-"

              //guardarUsuario()
            }
          }
        }); 
      } else { 
        console.log("Geolocation is not supported by this browser.");
      } 
    }
    function success(pos) {
      var crd = pos.coords;
      getLocationInfo(crd.latitude, crd.longitude);
    }
    
    function errors(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      imgLoading.current.style.display="none"
    }

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const messageParam = searchParams.get('message');
      if (messageParam) {
        setMessage(messageParam);
        handleShow();
      }

      if(!authenticated) regresarAlMain()
    }, [location.search]);
    

    useEffect(() => {
      if(usuario.id!==undefined){
        axios.get(
            `${API_BASE_URL}/api/v1/profiles/${usuario.id}`,
            tokenBearer
        ).then(res => {
            if(res.data){
              setProfileData(res.data);
              //cargarCiudadesPorPais(res.data.paisId)
              //setSelCiudad(res.data.ciudadId)
            }
            else{
                setProfileData({
                    usuarioId: usuario.id,
                    leer:false,
                    practicarDeportes:false,
                    cocinar:false,
                    verPeliculas:false,
                    HacerEjercicio:false,
                    viajar:false,
                    videoJuegos:false,
                    nuevosIdiomas:false,
                    bailar:false,
                    escribirPoesia:false,

                    reggaeton:false,
                    rap:false,
                    electronica:false,
                    jazz:false,
                    salsa:false,
                    metal:false,
                    pop:false,
                    clasica:false,

                    emprenderMiNegocio:false,
                    viajarPorElMundo:false,
                    graduarmeDeLaUniversidad:false,
                    aprendeUnNuevoIdioma:false,
                    tenerUnaFamilia:false,
                    serArtista:false,
                    serUnAtleta:false,
                    serReconocidoEnMiTrabajo:false,
                    publicarUnLibro:false,

                    aireLibre:false,
                    interiores:false,

                    misterio:false,
                    comediasRomanticas:false,
                    documentales:false,
                    terror:false,
                    accion:false,
                    drama:false,
                    fantasia:false,
                    biografias:false,
                })
            }
        });

        setUsuarioData(usuario)
      }
    }, [usuario]);

    useEffect(()=>{
      if(usuario.ciudad=="")
        setTimeout(()=>{
          buscarUbicacion();
        }, 2000)
      else
        console.log("no buscar ubicación "+usuario.ciudad)
    },[])


    if(!authenticated){
      return (
          <Navigate to="/login"/>
      );
    }


    function regresarAlMain(){
        return (
            <Navigate to="/"/>
        );
    }

    const guardarUsuario = () => {
        if(usuario){
            setMostrarGifPerfil(true)
            axios.post(
                `${API_BASE_URL}/api/v1/users/${usuario.id}`, 
                usuarioData,
                tokenBearer
            ).then(res=>{
              setTimeout(()=>{
                setMostrarGifPerfil(false)
                setMostrarOkPerfil(true)

                setTimeout(()=>{
                  setMostrarOkPerfil(false)
                },1500)
              },500)
            });
        }
    };
    const guardarProfile = () => {
        if(usuario){
            setMostrarGifPregunta(true)
            axios.post(
                `${API_BASE_URL}/api/v1/profiles/${usuario.id}`, 
                profileData,
                tokenBearer
            ).then(res=>{
              setTimeout(()=>{
                setMostrarGifPregunta(false)
                setMostrarOkPregunta(true)

                setTimeout(()=>{
                  setMostrarOkPregunta(false)
                },1500)
              },500)
            });
        }
    };

    const handleClose = () => setShow(false);
    const handleShow = () =>{
        setShow(true);
        setTimeout(function(){
            document.getElementById("modal-mensaje")?.parentElement?.classList.remove("fade")
            console.log("ocultando")
        },300)
    }

    const handleChangeUsuario = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUsuarioData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleChangeProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        const name = e.target.name;

        setProfileData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleChangeSelectUsuario = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.target;
      setUsuarioData(prevState => ({
          ...prevState,
          [name]: value
      }));
    };

    return (
        <div>
            <section className="pt-4" id="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="section-title pb-3">
                                <h1>Tu información</h1>
                            </div>
                        </div>

                        <div className="wow fadeInUp col-md-4 col-sm-4" data-wow-delay="0.4s">
                            <div className="about-thumb">
                              <div className="p-5 py-0 marco-foto-perfil" style={{textAlign:'end'}}>
                                <img 
                                  src={usuarioData?.foto? usuarioData?.foto : FRONT_BASE_URL+"/images/no-foto.jpg"} 
                                  style={{borderRadius: '50%', width: '300px', height: '300px', objectFit:'cover'}} 
                                  className="img-responsive" alt="Team"
                                />
                                  
                                <label for="fotoPerfil" style={{transform: 'translate(0, -100%)', borderRadius:'50%', border: '2px dashed white', cursor:'pointer'}}>
                                  <i style={{fontSize:'40px'}} className="p-2 fas fa-camera text-light"></i>
                                </label>
                                <input id="fotoPerfil" style={{display:'none'}} type="file" accept="image/*" onChange={handleImageChange} />
                              </div>
                              <div className="about-overlay">
                                  <h2>{usuarioData?.nombre}</h2>
                                  <h6>{usuarioData?.correo}</h6>
                                  <ul className="social-icon">
                                      <li><a style={{color: 'white', fontSize:'20px'}} href={usuarioData?.perfilFacebook} className="fab fa-facebook"> </a></li>
                                      <li><a style={{color: 'white', fontSize:'20px'}} href={usuarioData?.perfilInstagram} className="fab fa-instagram"> </a></li>
                                  </ul>
                              </div>
                            </div>
                        </div>

                        <div className="wow fadeInUp col-md-8 col-sm-8" data-wow-delay="0.6s">
                            <div className="pricing-plan" style={{borderRadius: "20px", textAlign:"left", padding:"20px"}}>
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-9">
                                      <label>Nombre:</label>
                                      <input type="text" className='form-control' name={'nombre'} value={usuarioData?.nombre} onChange={handleChangeUsuario}/>
                                    </div>
                                    <div className="col-md-3">
                                      <label>Edad:</label>
                                      <input type="number" min="18" className='form-control text-end' name={'edad'} value={usuarioData?.edad} onChange={handleChangeUsuario}/>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6" style={{paddingTop:"10px"}}>
                                    <label>Correo electrónico:</label>
                                    <input type="text" className='form-control' value={usuarioData?.correo} readOnly/>
                                </div>
                                <div className="col-md-12" style={{paddingTop:"10px"}}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>País:</label>
                                            <input type="text" className='form-control' value={usuarioData?.pais} readOnly/>
                                        </div>
                                        <div className="col-md-3">
                                            <label>Ciudad:</label>
                                            <input type="text" className='form-control' value={usuarioData?.ciudad} readOnly/>
                                        </div>
                                        <div className="col-md-5 pb-1" style={{display:'flex', alignItems:'end'}}>
                                            <button onClick={buscarUbicacion} className="btn btn-sm btn-primary" style={{borderRadius:'50%'}}>
                                              <i className="fas fa-street-view"></i>
                                            </button>
                                            <label className="ps-2 pb-1">Buscar Ubicación</label>
                                            <img src="/images/loading.gif" ref={imgLoading} className='mb-1 ms-2' style={{width:'20px', height:'20px', display: 'none'}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{paddingTop:"10px"}}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Eres:</label>
                                            <select className="form-select" value={usuarioData?.sexo}  name={'sexo'} onChange={handleChangeSelectUsuario}>
                                                <option value="H">Hombre</option>
                                                <option value="M" selected>Mujer</option>
                                                <option value="O">Otro</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Estas buscando:</label>
                                            <select className="form-select" value={usuarioData?.interes} name={'interes'} onChange={handleChangeSelectUsuario}>
                                                <option value="H">Hombres</option>
                                                <option value="M" selected>Mujeres</option>
                                                <option value="O">Todos</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{paddingTop:"20px"}}>
                                    <label>Perfil de Facebook:</label>
                                    <input type="text" className='form-control' value={usuarioData?.perfilFacebook} name={'perfilFacebook'} onChange={handleChangeUsuario}/>
                                </div>
                                <div className="col-md-12" style={{paddingTop:"10px"}}>
                                    <label>Perfil de Instagram:</label>
                                    <input type="text" className='form-control' value={usuarioData?.perfilInstagram} name={'perfilInstagram'} onChange={handleChangeUsuario}/>
                                </div>
                                <div className="col-md-12 text-right" style={{paddingTop:"10px", display:'flex', alignItems:'center'}}>
                                    <button className="btn btn-primary" onClick={guardarUsuario} type={'button'}>
                                        Guardar
                                    </button>
                                    {mostrarGifPerfil? <img src="/images/loading.gif" className='ms-3' style={{width:'30px', height:'30px'}}/>: ''}
                                    {mostrarOkPerfil? <i className='ms-3 fa fa-check' style={{color:'green', fontSize:'26px'}}></i> : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="section-title pt-4 pb-2 d-flex">
                                <h1 className="col-md-12">Intereses {!usuario.filledProfile?<Asistente  usuario={usuario} authenticated={authenticated} />: ''}</h1>
                            </div>
                        </div>

                        <div className="wow fadeInUp col-md-12 col-sm-12" data-wow-delay="0.6s">
                            <div className="pricing-plan" style={{borderRadius: "20px", textAlign:"left", padding:"20px"}}>
                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Cuáles son tus pasatiempos o actividades favoritas?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="leer" className="form-check-input" type="checkbox" name="leer" checked={profileData?.leer} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="leer">Leer</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="practicarDeportes" className="form-check-input" type="checkbox" name="practicarDeportes" checked={profileData?.practicarDeportes} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="practicarDeportes">Practicar Deportes</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="cocinar" className="form-check-input" type="checkbox" name="cocinar" checked={profileData?.cocinar} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="cocinar">Cocinar</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="verPeliculas" className="form-check-input" type="checkbox" name="verPeliculas" checked={profileData?.verPeliculas} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="verPeliculas">Ver películas</label>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="hacerEjercicio" className="form-check-input" type="checkbox" name="hacerEjercicio" checked={profileData?.hacerEjercicio} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="hacerEjercicio">Hacer ejercicio</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="viajar" className="form-check-input" type="checkbox" name="viajar" checked={profileData?.viajar} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="viajar">Viajar</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="videoJuegos" className="form-check-input" type="checkbox" name="videoJuegos" checked={profileData?.videoJuegos} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="videoJuegos">Videojuegos</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="nuevosIdiomas" className="form-check-input" type="checkbox" name="nuevosIdiomas" checked={profileData?.nuevosIdiomas} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="nuevosIdiomas">Nuevos idiomas</label>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="bailar" className="form-check-input" type="checkbox" name="bailar" checked={profileData?.bailar} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="bailar">Bailar</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="escribirPoesia" className="form-check-input" type="checkbox" name="escribirPoesia" checked={profileData?.escribirPoesia} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="escribirPoesia">Escribir poesía</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="ninguna1" className="form-check-input" type="checkbox" name="ninguna1" checked={profileData?.ninguna1} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="ninguna1">Otras actividades</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Qué tipo de música te gusta escuchar?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="reggaeton" className="form-check-input" type="checkbox" name="reggaeton" checked={profileData?.reggaeton} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="reggaeton">Reggaeton</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="rap" className="form-check-input" type="checkbox" name="rap" checked={profileData?.rap} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="rap">Rap</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="electronica" className="form-check-input" type="checkbox" name="electronica" checked={profileData?.electronica} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="electronica">Electronica</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="jazz" className="form-check-input" type="checkbox" name="jazz" checked={profileData?.jazz} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="jazz">Jazz</label>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="salsa" className="form-check-input" type="checkbox" name="salsa" checked={profileData?.salsa} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="salsa">Salsa</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="metal" className="form-check-input" type="checkbox" name="metal" checked={profileData?.metal} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="metal">Metal</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="pop" className="form-check-input" type="checkbox" name="pop" checked={profileData?.pop} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="pop">Pop</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="clasica" className="form-check-input" type="checkbox" name="clasica" checked={profileData?.clasica} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="clasica">Clasica</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Tienes alguna meta o sueño que te gustaría alcanzar?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="emprenderMiNegocio" className="form-check-input" type="checkbox" name="emprenderMiNegocio" checked={profileData?.emprenderMiNegocio} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="emprenderMiNegocio">Emprender mi negocio</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="viajarPorElMundo" className="form-check-input" type="checkbox" name="viajarPorElMundo" checked={profileData?.viajarPorElMundo} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="viajarPorElMundo">Viajar por el mundo</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="graduarmeDeLaUniversidad" className="form-check-input" type="checkbox" name="graduarmeDeLaUniversidad" checked={profileData?.graduarmeDeLaUniversidad} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="graduarmeDeLaUniversidad">Graduarme de la universidad</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="aprendeUnNuevoIdioma" className="form-check-input" type="checkbox" name="aprendeUnNuevoIdioma" checked={profileData?.aprendeUnNuevoIdioma} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="aprendeUnNuevoIdioma">Aprender un nuevo idioma</label>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="tenerUnaFamilia" className="form-check-input" type="checkbox" name="tenerUnaFamilia" checked={profileData?.tenerUnaFamilia} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="tenerUnaFamilia">Tener una familia</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="serArtista" className="form-check-input" type="checkbox" name="serArtista" checked={profileData?.serArtista} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="serArtista">Ser artista</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="serUnAtleta" className="form-check-input" type="checkbox" name="serUnAtleta" checked={profileData?.serUnAtleta} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="serUnAtleta">Ser un atleta</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="serReconocidoEnMiTrabajo" className="form-check-input" type="checkbox" name="serReconocidoEnMiTrabajo" checked={profileData?.serReconocidoEnMiTrabajo} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="serReconocidoEnMiTrabajo">Ser reconocido en mi trabajo</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="publicarUnLibro" className="form-check-input" type="checkbox" name="publicarUnLibro" checked={profileData?.publicarUnLibro} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="publicarUnLibro">Publicar un libro</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="ninguna3" className="form-check-input" type="checkbox" name="ninguna3" checked={profileData?.ninguna3} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="ninguna3">Otros</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Prefieres pasar tiempo al aire libre o en interiores?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="aireLibre" className="form-check-input" type="checkbox" name="aireLibre" checked={profileData?.aireLibre} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="aireLibre">Aire libre</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="interiores" className="form-check-input" type="checkbox" name="interiores" checked={profileData?.interiores} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="interiores">Interiores</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Qué tipo de libros o películas disfrutas?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="misterio" className="form-check-input" type="checkbox" name="misterio" checked={profileData?.misterio} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="misterio">Misterio</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="comediasRomanticas" className="form-check-input" type="checkbox" name="comediasRomanticas" checked={profileData?.comediasRomanticas} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="comediasRomanticas">Comedias románticas</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="documentales" className="form-check-input" type="checkbox" name="documentales" checked={profileData?.documentales} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="documentales">Documentales</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="terror" className="form-check-input" type="checkbox" name="terror" checked={profileData?.terror} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="terror">Terror</label>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="accion" className="form-check-input" type="checkbox" name="accion" checked={profileData?.accion} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="accion">Acción</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="drama" className="form-check-input" type="checkbox" name="drama" checked={profileData?.drama} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="drama">Drama</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="fantasia" className="form-check-input" type="checkbox" name="fantasia" checked={profileData?.fantasia} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="fantasia">Fantasía</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="biografias" className="form-check-input" type="checkbox" name="biografias" checked={profileData?.biografias} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="biografias">Biografías</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-check">
                                                <input id="ninguna5" className="form-check-input" type="checkbox" name="ninguna5" checked={profileData?.ninguna5} onChange={handleChangeProfile}/>
                                                <label className="form-check-label" htmlFor="ninguna5">Otros</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Cuál es tu comida favorita?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="pizza" name="pizza"
                                                  checked={profileData?.pizza} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="pizza">Pizza</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="tacos" name="tacos"
                                                  checked={profileData?.tacos} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="tacos">Tacos</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="sushi" name="sushi"
                                                  checked={profileData?.sushi} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="sushi">Sushi</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="pasta" name="pasta"
                                                  checked={profileData?.pasta} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="pasta">Pasta</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="hamburguesa" name="hamburguesa"
                                                  checked={profileData?.hamburguesa} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="hamburguesa">Hamburguesa</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="parrillada" name="parrillada"
                                                  checked={profileData?.parrillada} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="parrillada">Parrillada</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="mariscos" name="mariscos"
                                                  checked={profileData?.mariscos} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="mariscos">Mariscos</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="paella" name="paella"
                                                  checked={profileData?.paella} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="paella">Paella</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="ramen" name="ramen"
                                                  checked={profileData?.ramen} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="ramen">Ramen</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="sandwiches" name="sandwiches"
                                                  checked={profileData?.sandwiches} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="sandwiches">Sandwiches</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="ceviche" name="ceviche"
                                                  checked={profileData?.ceviche} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="ceviche">Ceviche</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="tartas" name="tartas"
                                                  checked={profileData?.tartas} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="tartas">Tartas</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="vegetales" name="vegetales"
                                                  checked={profileData?.vegetales} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="vegetales">Vegetales</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="ninguna6" name="ninguna6"
                                                  checked={profileData?.ninguna6} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="ninguna6">Otros alimentos</label>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Tienes mascotas?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="perro" name="perro"
                                                  checked={profileData?.perro} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="perro">Perro</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="gato" name="gato"
                                                  checked={profileData?.gato} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="gato">Gato</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="hamster" name="hamster"
                                                  checked={profileData?.hamster} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="hamster">Hamster</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="loro" name="loro"
                                                  checked={profileData?.loro} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="loro">Loro</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="amfibio" name="amfibio"
                                                  checked={profileData?.amfibio} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="amfibio">Amfibio</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="conejo" name="conejo"
                                                  checked={profileData?.conejo} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="conejo">Conejo</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="pez" name="pez"
                                                  checked={profileData?.pez} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="pez">Pez</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="ninguna7" name="ninguna7"
                                                  checked={profileData?.ninguna7} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="ninguna7">Otras</label>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Qué tipo de viajes o aventuras te gustaría experimentar en el futuro?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="conversacionesProfundas" name="conversacionesProfundas"
                                                  checked={profileData?.conversacionesProfundas} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="conversacionesProfundas">Conversaciones Profundas</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="momentosDivertidos" name="momentosDivertidos"
                                                  checked={profileData?.momentosDivertidos} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="momentosDivertidos">Momentos Divertidos</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="cocinarJuntos" name="cocinarJuntos"
                                                  checked={profileData?.cocinarJuntos} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="cocinarJuntos">Cocinar Juntos</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="verPeliculas2" name="verPeliculas2"
                                                  checked={profileData?.verPeliculas2} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="verPeliculas2">Ver Películas</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="compartirJuegos" name="compartirJuegos"
                                                  checked={profileData?.compartirJuegos} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="compartirJuegos">Compartir Juegos</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="asistirAUnConcierto" name="asistirAUnConcierto"
                                                  checked={profileData?.asistirAUnConcierto} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="asistirAUnConcierto">Asistir a un Concierto</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="actividadesAlAireLibre" name="actividadesAlAireLibre"
                                                  checked={profileData?.actividadesAlAireLibre} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="actividadesAlAireLibre">Actividades al Aire Libre</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="practicarDeportesJuntos" name="practicarDeportesJuntos"
                                                  checked={profileData?.practicarDeportesJuntos} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="practicarDeportesJuntos">Practicar Deportes Juntos</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="ninguna8" name="ninguna8"
                                                  checked={profileData?.ninguna8} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="ninguna8">Otros viajes o aventuras</label>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{paddingBottom: "20px"}}>
                                    <label style={{fontWeight:"bold"}}>¿Cuáles son tus mejores cualidades?</label>
                                    <div className="row">
                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="empatico" name="empatico"
                                                  checked={profileData?.empatico} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="empatico">Empático</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="ordenado" name="ordenado"
                                                  checked={profileData?.ordenado} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="ordenado">Ordenado</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="creativo" name="creativo"
                                                  checked={profileData?.creativo} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="creativo">Creativo</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="paciente" name="paciente"
                                                  checked={profileData?.paciente} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="paciente">Paciente</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="comunicativo" name="comunicativo"
                                                  checked={profileData?.comunicativo} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="comunicativo">Comunicativo</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="honesto" name="honesto"
                                                  checked={profileData?.honesto} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="honesto">Honesto</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="sensible" name="sensible"
                                                  checked={profileData?.sensible} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="sensible">Sensible</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="perfeccionista" name="perfeccionista"
                                                  checked={profileData?.perfeccionista} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="perfeccionista">Perfeccionista</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="amable" name="amable"
                                                  checked={profileData?.amable} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="amable">Amable</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="respetuoso" name="respetuoso"
                                                  checked={profileData?.respetuoso} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="respetuoso">Respetuoso</label>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="ninguna9" name="ninguna9"
                                                  checked={profileData?.ninguna9} onChange={handleChangeProfile}/>
                                            <label className="form-check-label" htmlFor="ninguna9">Ninguna de esas</label>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-right" style={{paddingTop:"10px", display:'flex', alignItems:'center'}}>
                                    <button className="btn btn-primary" onClick={guardarProfile} type={'button'}>
                                        Guardar
                                    </button>
                                    {mostrarGifPregunta? <img src="/images/loading.gif" className='ms-3' style={{width:'30px', height:'30px'}}/>: ''}
                                    {mostrarOkPregunta? <i className='ms-3 fa fa-check' style={{color:'green', fontSize:'26px'}}></i>: ''}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            
            <Modal id="modal-mensaje" show={show} fade={false} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>Notificación de la Página</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{fontSize:"25px", color: "white"}}>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleClose} style={{fontSize:"30px"}}>
                    &nbsp;&nbsp; Aceptar &nbsp;&nbsp;
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProfileUser;