import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom'
import { FRONT_BASE_URL } from '../components/lib/constants';


const OAuth2RedirectHandler=()=>{
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const error = searchParams.get("error");

    console.log("token "+token)
    console.log("error "+error)

    if(token) {
      localStorage.setItem('accessToken', token);
      setTimeout(()=>{
        window.location.href=FRONT_BASE_URL+'/profile'
      }, 300)
    } else {
      return (
        <Navigate to="/login"/>
      );
    }
}

export default OAuth2RedirectHandler;