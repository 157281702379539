import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './login.css'
import { API_BASE_URL, FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL } from '../../lib/constants';
import { Navigate } from 'react-router';
import { LoginData } from '../../lib/types';

const Login = ({authenticated}) => {
  const [dataLogin, setDataLogin] = useState<LoginData>();
  const [errorLogin, setErrorLogin] = useState<Boolean>(false);
  const [successCreated, setSuccessCreated] = useState<Boolean>(false);

  useEffect(()=>{
    if(authenticated) window.location.href="/"

    console.log("autenticado "+authenticated)
  }, [authenticated])

  function regresarAlMain(){
    console.log("regresando al main")
    
  }

  useEffect(()=>{
    if(localStorage.getItem('created')){
      setSuccessCreated(true)
      localStorage.removeItem('created')
    }
  }, [])
  

  const login=()=>{
    if(dataLogin?.email=="")
        alert("Digita tu Correo")
    else if(dataLogin?.password=="")
        alert("Digita tu Clave para poder Iniciar Sesión")
    else{
        axios.post(
          `${API_BASE_URL}/api/v1/auth/login`, 
          dataLogin
        )
        .then(res=>{
          if(res.data.result=='ok'){
            localStorage.setItem("accessToken", res.data.token)
            window.location.href="/"
          }
          else{
            alert(res.data.message)
          }
          console.log("logueado correctamente")
          console.log(res.data)
        })
        .catch(function (error) {
          setErrorLogin(true)
          console.log(error.toJSON());
        });
      
    }
  }

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataLogin(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  return (
    <div style={{background: 'linear-gradient(to left, #4776E6, #8E54E9)'}}>
      <section className="register-account">
        <div className="signform">
          <div className="right">
            <div className="headit">
              <h4><i className="fas fa-user"></i>&nbsp; Ingresa a tu Cuenta</h4>
            </div>
            <div className="form">
              {successCreated? <span style={{color: 'green'}}>Listo!!!&nbsp; Te has registrado correctamente!!!</span>: ''}
              <form action={API_BASE_URL+"/api/v1/auth/login"} className="login-form" id="login-form">
                  <input type="text" name="email" placeholder="Ingresa tu Correo" onChange={handleChangeData} style={{ background: errorLogin? '#ffcaca': 'white'}}/>
                  <br />
                  <input type="password"
                    name="password"
                    placeholder="Clave"
                    onChange={handleChangeData} 
                    style={{ background: errorLogin? '#ffcaca': 'white', marginTop:'15px', width:'100%'}}
                  />
                  {
                    errorLogin? <span style={{fontSize:'10px', color:'red'}}>Correo o Clave inválidos</span>:''
                  }
                  <br /><br />
                  <button className="subbt" type="button" onClick={login} style={{border:'none'}} >Iniciar Sesión</button>
              </form>
              <a href="/register" style={{float:'right', marginRight:'35px', fontSize: '0.9em'}} className="bts-a">
                ¿No tienes una Cuenta? Regístrate!
              </a>
            </div>
          </div>
          <div className="px-4 left">
            <div className="headit mb-4 pt-2">
              <h4><i className="fas fa-share-alt"></i> &nbsp; Usa redes sociales</h4>
            </div>
            
            <div className="bts">
              <a href={FACEBOOK_AUTH_URL} className="fblogin social"><i className="fab fa-facebook"></i><span>Inicia sesión con Facebook</span></a>
              <a href={GOOGLE_AUTH_URL} className="gplogin social"><i className="fab fa-google"></i><span>Inicia sesión con Google</span></a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;