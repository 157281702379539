import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import "./estilo.css";
import logo from "../../../../src/icono_match_express.png"


const AppHeader = ({authenticated, onLogout, usuario})=> {
    const navigate = useNavigate();


    const handleGoPerfil=()=>{
      navigate("/profile")
    }

    const handleGoMain=()=>{
      navigate('/main')
    }
    const handleGoChats=()=>{
        navigate('/chats')
    }

    return (
        <div className="navbar navbar-default navbar-fixed-top" style={{height: '10vh'}}>
            <div className="container">
                <div className="navbar-header col-md-12" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <a href="/" className="navbar-brand" style={{marginRight:'auto'}}>
                      <img src={logo} style={{width:'130px', transform: 'translateY(-50%)', position:'absolute'}}/>
                    </a>
                    {authenticated?
                        (
                          <div style={{display: 'flex'}} className="marco-menu-botones">
                            <a title="Buscar Amigos"  onClick={handleGoMain} className="rounded-circle m-2 boton-circular">
                                <i className="fas fa-users text-dark"></i>
                            </a>

                            <a title="Ir a la Ventana de Chats" onClick={handleGoChats} className="rounded-circle m-2 boton-circular">
                                <i className="fas fa-comment text-dark"></i>
                            </a>

                            <a title="Editar tú Perfil" onClick={handleGoPerfil} className="rounded-circle m-2 boton-circular">
                                <i className="fas fa-edit text-dark"></i>
                            </a>

                            <span class="ms-1 lbl-etiqueta" style={{fontWeight: '900',alignContent: 'center',justifyItems: 'center',fontSize: '20px', color: '#0068ff'}}>
                              {usuario.nombre}
                            </span>

                            <button title="Cerrar Sesión" className="ms-1 btn btn-inline-danger rouded-circle" onClick={onLogout}>
                                <i className="fas fa-sign-out-alt text-danger" style={{fontSize:'40px'}}></i>
                            </button>
                          </div>
                        )
                        :
                        ('')
                    }
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav navbar-right">
                        <li><a href="/home" className="smoothScroll">Inicio</a></li>
                        <li><a href="/profile" className="smoothScroll">Perfil</a></li>
                        <li><a href="/matchs" className="smoothScroll">Tus matchs</a></li>
                        <li><a href="#pricing" className="smoothScroll">Precios</a></li>
                        <li><a href="#about" className="smoothScroll">Acerca de</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AppHeader;


