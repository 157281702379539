import React, { useEffect, useState } from 'react'
import { getCurrentUser, getGenero, getInteres } from '../../lib/api.ts';
import { Usuario } from '../../lib/types';
import { API_BASE_URL, tokenBearer } from '../../lib/constants';
import axios from 'axios';
import { Navigate } from 'react-router';
import './userRow.css';

const UserRow=({userMatch, match}) =>{
    const [currentUser, setCurrentUser] = useState<Usuario>({});
    const [redirect, setRedirect] = useState(false);

    useEffect(()=>{
        if(currentUser.id==null){
            getCurrentUser()
                .then(response => {
                    setCurrentUser(response)
                })
                .catch(error => {
                    console.log("Ocurrió un problema al consultar tu Saldo. Intenta mas tarde")
                });
        }
    }, [currentUser])

    const handleAbrirChat=(idUsuario, tipo)=>{
        if(tipo==1){
          if(currentUser.cantidadCreditos==0)
            alert("No tienes Créditos disponibles, intenta comprando créditos")
          else{
            if(window.confirm(`¿Deseas abrir este Chat para conectarte con ${userMatch?.nombre}?, \n Esto descontará un crédito de tu Saldo actual`)){
              axios.post(
                  `${API_BASE_URL}/rooms`,{
                      userMatchId: idUsuario,
                      currentUserId: currentUser.id
                  },
                  tokenBearer
              ).then(res =>{
                  localStorage.setItem("seleccionarChat", idUsuario);
                  setRedirect(true);
              });
            }
          }
        }
        else{
          localStorage.setItem("seleccionarChat", idUsuario)
          setRedirect(true);
        }
    }

    if (redirect) {
        return <Navigate to="/chats" />;
    }

    return (
        <div className="wow fadeInUp col-md-3 p-2 marco-user-foto" style={{background: '#white', borderRadius: '5px'}}>
          <div className="col-md-12 m-0 p-0" style={{position: 'relative'}}>
              <img className='foto-user-busqueda' src={`${userMatch?.foto}`} style={{width: "100%", height:'280px', objectFit: 'cover', borderRadius: '20px 20px 0 0'}} />
              <div className="col-md-12 pe-2 marco-user-redes" style={{position:'absolute', transform: 'translateY(-110%)',display:'flex', justifyContent: 'end'}}>
                {userMatch.facebook!=""?
                  <a href={userMatch.facebook}  className="btn btn-sm btn-white"
                    data-bs-toggle="tooltip" data-bs-placement="top" title='Ir a facebook'>
                      <i className="fab fa-facebook" style={{color: 'black', fontSize: '20px', fontWeight: '900', textShadow: '0 0 0.2em #ffffff'}}></i>
                  </a>
                  :
                  ""
                }
                {userMatch.instagram!=""?
                  <a href={userMatch.instagram} className="btn btn-sm btn-white"
                    data-bs-toggle="tooltip" data-bs-placement="top" title='Abrir en Instagram'>
                      <i className="fab fa-instagram" style={{color: 'black', fontSize: '20px', fontWeight: '900', textShadow: '0 0 0.2em #ffffff'}}></i>
                  </a>
                  :
                  ""
                }
              </div>
              <div className="col-md-12 p-0 pe-1 marco-user-ciudad" style={{position:'absolute', top:'5px', width:'100%', textAlign:'end'}}>
                <label title={userMatch?.ciudad+" - "+userMatch?.pais} style={{textAlign:'end', width:'100%', textShadow:'-1px -1px white, 1px 1px #333'}}>{userMatch.ciudad}</label>
              </div>
          </div>
          
          <div className="col-md-12 p-0 py-2 marco-user-datos" style={{background: '#e0e0e0', borderRadius: '0 0 10px 10px', textAlign:'center'}}>
            <div className="col-md-12 ps-0" style={{alignContent: 'center'}}>
                <h5 className="p-0 m-0" style={{color: 'black', textAlign: 'center', fontWeight: '900'}}>{userMatch?.nombre}</h5>
            </div>
            <div className="col-md-12" style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
                <h5 className="p-1 m-0" style={{color: '#545454', fontSize:'14px'}}>
                  Género: <span style={{fontWeight: 'bold'}}>{getGenero(userMatch.sexo)}</span>
                  &nbsp;&nbsp;&nbsp;
                  Match <span style={{fontWeight: 'bold'}}>{userMatch.compatibilidad}%</span>
                </h5>
            </div>
            <div className="col-md-12" style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
                <h5 className="p-1 m-0" style={{color: '#545454', fontSize:'14px'}}>
                  Interés: <span style={{fontWeight: 'bold'}}>{getInteres(userMatch.interes)}</span>
                </h5>
            </div>
            {!match?
                <button type="button" onClick={()=>handleAbrirChat(userMatch?.id, 1)} className="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top" title='Abrir Chat'>
                    Iniciar &nbsp; Chat
                </button>
                :
                <button type="button" onClick={()=>handleAbrirChat(userMatch?.id, 2)} className="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title='Abrir Chat'>
                    Seguir &nbsp; Chateando
                </button>
            }
          </div>
        </div>
    )
}

export default UserRow; 