//export const API_BASE_URL = 'http://localhost:8080';
//export const API_BASE_URL = 'https://social-match-server-g2sk.onrender.com';
export const API_BASE_URL = 'http://ec2-3-16-42-139.us-east-2.compute.amazonaws.com:8080';

//export const FRONT_BASE_URL = 'http://localhost:3000';
export const FRONT_BASE_URL = 'https://supercarsnft.net';

export const OAUTH2_REDIRECT_URI = FRONT_BASE_URL+'/oauth2/redirect';
export const GOOGLE_AUTH_URL = API_BASE_URL + "/oauth2/authorize/google?redirect_uri="+FRONT_BASE_URL+"/oauth2/redirect";
export const FACEBOOK_AUTH_URL = API_BASE_URL + "/oauth2/authorize/facebook?redirect_uri="+FRONT_BASE_URL+"/oauth2/redirect";


export const tokenBearer={
    headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        'Access-Control-Allow-Origin': true,
    }
}
