import React from "react";
import { useEffect, useState } from "react";
import { ChatMessage, Usuario } from "../lib/types";
import { format } from 'date-fns';
import axios from "axios";

interface ChatRowProps {
  item: ChatMessage;
  usuarioId:String | undefined;
  fotoUsuario: String | undefined;
  fotoDestinatario: String | undefined;
}

const ChatRow = ({ item, usuarioId, fotoUsuario, fotoDestinatario }: ChatRowProps) => {
  const [isFromLocalUser, setIsFromLocalUsuer] = useState<boolean>(true);
  
  useEffect(()=>{
    if(usuarioId!=item.senderId){
      setIsFromLocalUsuer(false)
    }
  }, [isFromLocalUser])

  const formattedDate = item.createdDate ? format(item.createdDate, "dd/MM/yyyy '    a  las  ' HH'h'mm") : '';

  return (
    <li className="list-unstyled">
      <div className="col-md-12">
          {!isFromLocalUser?
            <>
            <div style={{display: 'flex'}}>
              <div className="px-0">
                  <img src={`${fotoDestinatario}`} style={{width: "50px", height: "50px",borderRadius:"50%"}} />
              </div>
              <div className="col-md-4 px-0">
                <div className="col-md-12 rounded ms-1 p-1" style={{backgroundColor: 'white', color: 'black'}} >
                  <p className="m-0 px-2">{item.message}</p>
                </div>
                
              </div>
            </div>
            <h6 className="p-1 m-0 mb-3" style={{fontSize: '12px', textAlign:'start'}}>{formattedDate}</h6>
            </>
          :
            ''
          }
          

          {isFromLocalUser?
            <>
            <div className="mensaje-derecha" style={{display: 'flex'}}>
              <div className="col-md-4 px-0">
                <div className="col-md-12 rounded ms-1 p-1" style={{backgroundColor: 'white', color: 'black'}} >
                  <p className="m-0 px-2" style={{textAlign: 'right'}}>{item.message}</p>
                </div>
              </div>
            </div>
            <h6 className="p-1 m-0 mb-3" style={{fontSize: '10px', textAlign:'end'}}>{formattedDate}</h6>
            </>
          :
            ''
          }
        </div>
    </li>
  );
};

export default ChatRow;
