import { API_BASE_URL } from "./constants";
import { ChatMessage } from "./types";


export async function addMessage(item: ChatMessage) {
  const data = await fetch(`${API_BASE_URL}/chats`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(item),
  });
  return data.status;
}




const request = options => {
  const headers = new Headers({
    "Content-Type": "application/json"
  });

  if (localStorage.getItem("accessToken")) {
    headers.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then(response =>
    response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function getCurrentUser() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/api/v1/users/profile",
    method: "GET"
  });
}

export function getGenero(valorGenero){
  switch (valorGenero) {
    case "H":
      return 'Hombre';
    case "M":
      return 'Mujer';
    case "O":
      return 'Gay';
    default:
      return 'Desconocido';
  }
}
export function getInteres(valorInteres){
  switch (valorInteres) {
    case "H":
      return 'Hombres';
    case "M":
      return 'Mujeres';
    case "O":
      return 'Cualquier género';
    default:
      return 'Desconocido';
  }
}
