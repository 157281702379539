import React, { useEffect, useState } from "react";
import { Profile, Usuario } from "../../lib/types";
import "./style.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL, tokenBearer } from "../../lib/constants";
import { Navigate } from "react-router";

const pagesData = [
  { 
    title: 'Bienvenido a The Match Express',
    introduction: true,
    content: [
      'Para hacer Match, primero completa este asistente que te guiará a través de una serie de preguntas.',
      'Por favor, selecciona tus opciones favoritas y presiona "Siguiente" para comenzar.'
    ],
    checkboxes: [],
    name: []
  },
  { title: '¿Cuáles son tus pasatiempos o actividades favoritas?',
    checkboxes: [
      'Leer',
      'Practicar deportes',
      'Cocinar',
      'Ver películas',
      'Hacer ejercicio',
      'Viajar',
      'Videojuegos',
      'Nuevos idiomas',
      'Bailar',
      'Escribir poesía',
      'Ninguna de esas'
    ],
    name: [
      'leer',
      'practicarDeportes',
      'cocinar',
      'verPeliculas',
      'hacerEjercicio',
      'viajar',
      'videoJuegos',
      'nuevosIdiomas',
      'bailar',
      'escribirPoesia',
      'ninguna1'
    ]
  },
  { title: '¿Qué tipo de música te gusta escuchar?',
    checkboxes: [
      'Reggaeton',
      'Rap',
      'Electrónica',
      'jazz',
      'Salsa',
      'Metal',
      'Pop',
      'Clasica',
      'Ninguno de los anteriores'
    ],
    name: [
      'reggaeton',
      'rap',
      'electronica',
      'jazz',
      'salsa',
      'metal',
      'pop',
      'clasica',
      'ninguna2',
    ]
  },
  { title: '¿Tienes alguna meta o sueño que te gustaría alcanzar?',
    checkboxes: [
      'Emprender mi negocio',
      'Viajar por el mundo',
      'Graduarme de la universidad',
      'Aprender un nuevo idioma',
      'Tener una familia',
      'Ser artista',
      'Ser un atleta',
      'Ser reconocido en mi trabajo',
      'Publicar un libro',
      'Ninguna de las anteriores'
    ],
    name: [
      'emprenderMiNegocio',
      'viajarPorElMundo',
      'graduarmeDeLaUniversidad',
      'aprendeUnNuevoIdioma',
      'tenerUnaFamilia',
      'serArtista',
      'serUnAtleta',
      'serReconocidoEnMiTrabajo',
      'publicarUnLibro',
      'ninguna3'
    ]
  },
  { title: '¿Prefieres pasar tiempo al aire libre o en interiores?',
    checkboxes: [
      'Aire libre',
      'Interiores'
    ],
    name: [
      'aireLibre',
      'interiores'
    ]
  },
  { title: '¿Qué tipo de libros o películas disfrutas?',
    checkboxes: [
      'Misterio',
      'Comedias románticas',
      'Documentales',
      'Terror',
      'Acción',
      'Drama',
      'Fantasía',
      'Biografías',
      'No me gustan ninguno',
    ],
    name: [
      'misterio',
      'comediasRomanticas',
      'documentales',
      'terror',
      'accion',
      'drama',
      'fantasia',
      'biografias',
      'ninguna5'
    ]
  },
  { title: '¿Cuál es tu comida favorita?',
    checkboxes: [
      'Pizza',
      'Tacos',
      'Sushi',
      'Pasta',
      'Hamburguesa',
      'Parrillada',
      'Mariscos',
      'Paella',
      'Ramen',
      'Sandwiches',
      'Ceviche',
      'Tartas',
      'Vegetales',
      'Ninguno'
    ],
    name: [
      'pizza',
      'tacos',
      'sushi',
      'pasta',
      'hamburguesa',
      'parrillada',
      'mariscos',
      'paella',
      'ramen',
      'sandwiches',
      'ceviche',
      'tartas',
      'vegetales',
      'ninguna6'
    ]
  },
  { title: '¿Tienes mascotas?',
    checkboxes: [
      'perro',
      'gato',
      'hamster',
      'loro',
      'amfibio',
      'conejo',
      'pez',
      'Ninguna de esas'
    ],
    name: [
      'perro',
      'gato',
      'hamster',
      'loro',
      'amfibio',
      'conejo',
      'pez',
      'ninguna7'
    ]
  },
  { title: '¿Qué tipo de viajes o aventuras te gustaría experimentar en el futuro?',
    checkboxes: [
      'Conversaciones profundas',
      'Momentos divertidos',
      'Cocinar juntos',
      'Ver peliculas',
      'Compartir juegos',
      'Asistir a un concierto',
      'Actividades al aire libre',
      'Practicar deportes juntos',
      'Ninguno/a',
    ],
    name: [
      'conversacionesProfundas',
      'momentosDivertidos',
      'cocinarJuntos',
      'verPeliculas2',
      'compartirJuegos',
      'asistirAUnConcierto',
      'actividadesAlAireLibre',
      'practicarDeportesJuntos',
      'ninguna8',
    ]
  },
  { title: '¿Cuáles son tus mejores cualidades?',
    checkboxes: [
      'Empatico',
      'Ordenado',
      'Creativo',
      'Paciente',
      'Comunicativo',
      'Honesto',
      'Sensible',
      'Perfeccionista',
      'Amable',
      'Respetuoso',
      'Ninguna de estas'
    ],
    name: [
      'empatico',
      'ordenado',
      'creativo',
      'paciente',
      'comunicativo',
      'honesto',
      'sensible',
      'perfeccionista',
      'amable',
      'respetuoso',
      'ninguna9'
    ]
  },
];

const Asistente=({usuario, authenticated})=>{
    const [usuarioData, setUsuarioData] = useState<Usuario>();
    const [profileData, setProfileData] = useState<Profile>();
    const [showAsistente, setShowAsistente] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);
    const [currentPage, setCurrentPage] = useState(-1);
    const [checkboxStates, setCheckboxStates] = useState(pagesData.map(page => page.name.reduce((acc, name) => {
      acc[name] = false;
      return acc;
    }, {})));

    useEffect(() => {
      if(usuario.id!==undefined){
        axios.get(
            `${API_BASE_URL}/api/v1/profiles/${usuario.id}`,
            tokenBearer
        ).then(res => {
            if(res.data){
              setProfileData(res.data);

              const newCheckboxStates = checkboxStates.map((pageState, pageIndex) => {
                  const updatedPageState = { ...pageState };
                  pagesData[pageIndex].name.forEach(name => {
                      if (res.data[name] !== undefined) {
                          updatedPageState[name] = res.data[name];
                      }
                  });
                  return updatedPageState;
              });
              setCheckboxStates(newCheckboxStates);
            }
            else{
                setProfileData({
                    usuarioId: usuario.id,
                    leer:false,
                    practicarDeportes:false,
                    cocinar:false,
                    verPeliculas:false,
                    HacerEjercicio:false,
                    viajar:false,
                    videoJuegos:false,
                    nuevosIdiomas:false,
                    bailar:false,
                    escribirPoesia:false,
                    ninguna1:false,

                    reggaeton:false,
                    rap:false,
                    electronica:false,
                    jazz:false,
                    salsa:false,
                    metal:false,
                    pop:false,
                    clasica:false,
                    ninguna2:false,

                    emprenderMiNegocio:false,
                    viajarPorElMundo:false,
                    graduarmeDeLaUniversidad:false,
                    aprendeUnNuevoIdioma:false,
                    tenerUnaFamilia:false,
                    serArtista:false,
                    serUnAtleta:false,
                    serReconocidoEnMiTrabajo:false,
                    publicarUnLibro:false,
                    ninguna3:false,

                    aireLibre:false,
                    interiores:false,

                    misterio:false,
                    comediasRomanticas:false,
                    documentales:false,
                    terror:false,
                    accion:false,
                    drama:false,
                    fantasia:false,
                    biografias:false,
                    ninguna5:false,

                    pizza:false,
                    tacos:false,
                    sushi:false,
                    pasta:false,
                    hamburguesa:false,
                    parrillada:false,
                    mariscos:false,
                    paella:false,
                    ramen:false,
                    sandwiches:false,
                    ceviche:false,
                    tartas:false,
                    vegetales:false,
                    ninguna6:false,

                    perro:false,
                    gato:false,
                    hamster:false,
                    loro:false,
                    amfibio:false,
                    conejo:false,
                    pez:false,
                    ninguna7:false,


                    conversacionesProfundas:false,
                    momentosDivertidos:false,
                    cocinarJuntos:false,
                    verPeliculas2:false,
                    compartirJuegos:false,
                    asistirAUnConcierto:false,
                    actividadesAlAireLibre:false,
                    practicarDeportesJuntos:false,
                    ninguna8:false,

                    empatico:false,
                    ordenado:false,
                    creativo:false,
                    paciente:false,
                    comunicativo:false,
                    honesto:false,
                    sensible:false,
                    perfeccionista:false,
                    amable:false,
                    respetuoso:false,
                    ninguna9:false,
                })
            }

            if(!usuario?.filledProfile){
              setCurrentPage(-1)
              handleShowAsistente()
            }
        });

        setUsuarioData(usuario)
      }
    }, [usuario]);

    const handleCloseAsistente = () => setShowAsistente(false);
    const handleShowAsistente = () =>{
      setCurrentPage(0)
      setShowAsistente(true);
    }


    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleNext = () => {
        if (!Object.values(checkboxStates[currentPage]).some(value => value === true) && !pagesData[currentPage].introduction) {
            alert('Debe marcar al menos una opción antes de continuar.');
            return;
        }

        if (currentPage < pagesData.length - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleCheckboxChange = (pageIndex, checkboxName) => {
        setCheckboxStates(prevStates => {
            const newStates = [...prevStates];
            newStates[pageIndex] = {
                ...newStates[pageIndex],
                [checkboxName]: !newStates[pageIndex][checkboxName]
            };
            return newStates;
        });

        setProfileData(prevData => ({
          ...prevData,
          [checkboxName]: !prevData[checkboxName]
        }));

        console.log(checkboxStates)
    };

    const guardarProfile = () => {
        if (!Object.values(checkboxStates[currentPage]).some(value => value === true)) {
          alert('Debe marcar al menos una opción antes de Guardar.');
          return;
        }
        if(usuario){
            axios.post(
                `${API_BASE_URL}/api/v1/profiles/${usuario.id}`, 
                profileData,
                tokenBearer
            ).then(res=>{
              setTimeout(()=>{
                if(currentPage)
                window.location.reload();
              },500)
            });
        }
    };

    return (
      <>
          <Button variant="primary" onClick={handleShowAsistente}>
              <i className="fas fa-edit text-light me-2"></i>
              Abrir Asistente
          </Button>

          {showAsistente && (
              <div className="modal">
                  <div className="modal-dialog">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title">Bienvenido a &nbsp;<strong style={{fontSize: '24px'}}>The Match Express</strong></h5>
                              {usuario.filledProfile?
                                (<button type="button" className="btn btn-danger" onClick={handleCloseAsistente}>Cerrar</button>)
                              :
                                ('')
                              }
                          </div>
                          <div className="modal-body">
                              {pagesData[currentPage].introduction ? (
                                    pagesData[currentPage].content?.map((paragraph, index) => (
                                      <p className="mt-4" key={index}>{paragraph}</p>
                                    ))
                                ) : (
                                  <>
                                    <h4>{currentPage+' / 9'} &nbsp;&nbsp;&nbsp;&nbsp; {pagesData[currentPage].title}</h4>
                                    <div className="row options">
                                      {pagesData[currentPage].checkboxes.map((desc, index) => (
                                          <div className="col-md-3 form-check" key={index}>
                                              <input type="checkbox"
                                                className="form-check-input"
                                                id={`checkbox${currentPage}_${index}`} 
                                                name={pagesData[currentPage].name[index]}
                                                checked={checkboxStates[currentPage][pagesData[currentPage].name[index]]}
                                                onChange={() => handleCheckboxChange(currentPage, pagesData[currentPage].name[index])}
                                              />
                                              <label className="form-check-label" htmlFor={`checkbox${currentPage}_${index}`}>
                                                  {desc}
                                              </label>
                                          </div>
                                          ))
                                      }
                                    </div>
                                  </>
                                )
                              }
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handlePrev} disabled={currentPage === 0}>
                                Anterior
                            </button>
                            {currentPage<9 ?
                              (
                                <button type="button" className="btn btn-primary" onClick={handleNext} disabled={currentPage === pagesData.length - 1}>
                                    Siguiente
                                </button>
                              )
                              :
                              (
                                <button type="button" className="btn btn-success" onClick={guardarProfile}>
                                  Guardar
                                </button>
                              )
                            }
                          </div>
                      </div>
                  </div>
              </div>
          )}
      </>
    );
};

export default Asistente;