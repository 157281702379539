import React, { useEffect } from 'react'
import { Navigate } from 'react-router';

function Landing({authenticated}){
    if(authenticated){
        return (
            <Navigate to="/main"/>
        );
    }

    return (
        <div>
            <section id="home" className="main">
                <div className="overlay">
                </div>
                <div className="container" style={{zIndex:'9999'}}>
                    <div className="row">
                        <div className="wow fadeInUp col-md-6 col-sm-5 col-xs-10 col-xs-offset-1 col-sm-offset-0" data-wow-delay="0.2s">
                            <img src="/images/home-img.png" className="img-responsive" alt="Home"/>
                        </div>
                        <div className="col-md-6 col-sm-7 col-xs-12">
                            <div className="home-thumb">
                                <h1 className="wow fadeInUp" data-wow-delay="0.6s">ENCUENTRA PAREJA</h1>
                                <p className="wow fadeInUp" data-wow-delay="0.8s">¡Busca a tu amigo/a ideal, con tus mismos gustos, aficiones para entablar una bonita relación!</p>
                                <a href="/login" className="wow fadeInUp section-btn btn btn-success smoothScroll" data-wow-delay="1s">Inicia Sesión</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="section-title">
                                <h1>Suscripciones disponibles</h1>
                            </div>
                        </div>

                        <div className="wow fadeInUp col-md-4 col-sm-4" data-wow-delay="0.4s">
                            <div className="pricing-plan" style={{height:"415px"}}>
                                <div className="pricing-month">
                                    <h2>Gratis</h2>
                                </div>
                                <div className="pricing-title">
                                    <h3>Sin Suscripción</h3>
                                </div>
                                <p>1 intento por semana</p>
                                <p>Acceso a la Plataforma</p>
                                <p>Chats siempre disponibles</p>
                            </div>
                        </div>

                        <div className="wow fadeInUp col-md-4 col-sm-4" data-wow-delay="0.6s">
                            <div className="pricing-plan" style={{height: "415px"}}>
                                <div className="pricing-month">
                                    <h2>$0.50</h2>

                                </div>
                                <div className="pricing-title">
                                    <h3>Intento individual</h3>
                                </div>
                                <p>Compras sin suscribirte</p>
                                <p>1 intento adicional</p>
                                <p>Cuando lo requieras</p>
                                <p style={{color: "white"}}>.</p>
                                <a style={{cursor:'pointer'}} href="/login" className="btn btn-default section-btn">Comprar</a>
                            </div>
                        </div>

                        <div className="wow fadeInUp col-md-4 col-sm-4" data-wow-delay="0.8s">
                            <div className="pricing-plan" style={{height:"415px"}}>
                                <div className="pricing-month">
                                    <h2>$4.99</h2>
                                </div>
                                <div className="pricing-title">
                                    <h3>Suscripción</h3>
                                </div>
                                <p>20 intentos</p>
                                <p>Válida por 1 mes</p>
                                <p>Soporte ilimitado</p>
                                <p>Otros beneficios</p>
                                <a style={{cursor:'pointer'}} href="/login" className="btn btn-default section-btn">Comprar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="wow bounceIn section-title">
                                <h1>Bienvenido a Match Express</h1>
                                <hr/>
                            </div>
                        </div>
                        <div className="wow fadeInUp col-md-6 col-sm-12" data-wow-delay="0.4s">
                            <h2>BUSCA A TU PAREJA SEGÚN TUS GUSTOS</h2>
                            <h3 className="wow fadeInUp" data-wow-delay="0.8s">Nuestro sitio buscar a tu perfect match según tu perfil</h3>
                            <p className="wow fadeInUp" data-wow-delay="0.4s">
                              Cuando encuentres a esa persona, tendrás la oportunidad de chatear con ella, podrás abrir un nuevo chat con los intentos disponibles, 
                              tendrás 1 intento cada semana.
                            </p>
                        </div>
                        <div className="wow fadeInUp col-md-3 col-sm-6" data-wow-delay="0.4s">
                            <div className="about-thumb">
                                <img src="images/team-img1.jpg" className="img-responsive" alt="Team"/>
                                <div className="about-overlay">
                                    <h3>Andrea</h3>
                                    <h4>Ama de Casa</h4>
                                    <ul className="social-icon">
                                        <li><a href="/facebook.com" className="fa fa-facebook"> </a></li>
                                        <li><a href="/facebook.com" className="fa fa-instagram"> </a></li>
                                        <li><a href="/facebook.com" className="fa fa-twitter"> </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="wow fadeInUp col-md-3 col-sm-6" data-wow-delay="0.4s">
                            <div className="about-thumb">
                                <img src="images/team-img2.jpg" className="img-responsive" alt="Team"/>
                                <div className="about-overlay">
                                    <h3>Olga</h3>
                                    <h4>Abogada</h4>
                                    <ul className="social-icon">
                                        <li><a href="/facebook.com" className="fa fa-pinterest"> </a></li>
                                        <li><a href="/facebook.com" className="fa fa-behance"> </a></li>
                                        <li><a href="/facebook.com" className="fa fa-google-plus"> </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Landing;
