import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './register.css'
import { API_BASE_URL, FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL } from '../../lib/constants';
import { Navigate } from 'react-router';
import {Registro} from '../../lib/types';

const Register = ({authenticated}) => {
  const [dataRegistro, setDataRegistro] = useState<Registro>();
  const [repetirClave, setrepetirClave] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState('');

  useEffect(()=>{
    setDataRegistro({
      ...dataRegistro,
      sexo: 'H',
      interes: 'M',
    });
  }, [])

  useEffect(()=>{
    if(authenticated) regresarAlMain()
  }, [authenticated])

  function regresarAlMain(){
    return (
        <Navigate to="/"/>
    );
  }

  const crearCuenta=()=>{
    if(dataRegistro?.nombre=="")  alert("Digita un nombre de Usuario")
    else if(dataRegistro?.correo=="")  alert("Digita un correo para esta cuenta")
    else if(dataRegistro?.password=="")  alert("Tienes que especificar una Contraseña")
    else if(dataRegistro?.password!=repetirClave)  alert("Las Contraseñas deben concidir")
    else if(dataRegistro?.edad<18)  alert("Debes tener 18 años o más, para continuar")
    else{
      setTimeout(()=>{
        axios.post(
          `${API_BASE_URL}/api/v1/users/register`, 
          dataRegistro
        ).then(res=>{
          console.log("registro creado")
          console.log(res.data)
  
          localStorage.setItem('created', 'true')
          window.location.href="/login"
        });
      }, 300)
    }
  }

  const handleDateChange = (event) => {
    const today = new Date();
    const birthDate = new Date(event.target.value);
    const age = today.getFullYear() - birthDate.getFullYear();

    console.log("calculando "+today.getFullYear()+"   "+birthDate.getFullYear()+"   edad "+age)

    setDataRegistro({
      ...dataRegistro,
      edad: age,
    });

    setDateOfBirth(event.target.value);
  };

  const handleRepetirClaveChange = (event) => {
    setrepetirClave(event.target.value)
  };

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataRegistro(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  return (
    <div style={{background: 'linear-gradient(to left, #4776E6, #8E54E9)'}}>
      <section className="register-account">
        <div className="signform px-5">
          <div className="headit">
            <h4><i className="fas fa-user"></i>&nbsp; Ingresa&nbsp; tu&nbsp; Información&nbsp; Básica</h4>
          </div>
          <div className="form">
            <form className="login-form" id="login-form">
                <input type="text" name="nombre" placeholder="Nombres y Apellidos" onChange={handleChangeData}/>
                <input type="text" name="correo" placeholder="e-mail" onChange={handleChangeData}/>
                <h6>Fec. de Nacimiento:</h6>
                <input type="date" name="edad" onChange={handleDateChange}/>
                <br />
                <div style={{display:'flex', width:'100%'}}>
                  <div style={{width:'50%'}}>
                    <h6>Soy:</h6>
                    <select name="genero">
                      <option value="H">Hombre</option>
                      <option value="M">Mujer</option>
                      <option value="O">Otro</option>
                    </select>
                  </div>
                  <div style={{width:'50%'}}>
                    <h6>Me interesan:</h6>
                    <select name="interes">
                      <option value="H">Hombres</option>
                      <option value="M" selected>Mujeres</option>
                      <option value="O">Otros géneros</option>
                    </select>
                  </div>
                </div>
                <input style={{marginTop:'15px'}} type="password" name="password" placeholder="Clave" onChange={handleChangeData}/>
                <input style={{marginTop:'15px'}} type="password" name="passwordRepeat" placeholder="Repite la Clave" onChange={handleRepetirClaveChange}/>
                <button className="subbt" type="button" onClick={crearCuenta} style={{border:'none'}} >Crear Cuenta</button>
            </form>
            <a href="/login" style={{float:'right', marginRight:'35px', fontSize: '0.9em'}} className="bts-a">
              ¿Ya estás registrado? Inicia Sesión!
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;