import React, { useRef } from "react";
import { useState, useEffect, FC } from 'react';
import { ChatMessage, Room, Usuario } from '../lib/types';
import ChatRow from './ChatRow.tsx';
import { API_BASE_URL, tokenBearer } from "../lib/constants.js";
import axios from "axios";
import { Navigate } from "react-router";


interface ChatListProps {
  room: Room | undefined;
  usuario: Usuario;
}

const ChatList: FC<ChatListProps> = ({ room, usuario }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [roomAnterior, setRoomAnterior] = useState<Room>();
  const [usuarioDestinatario, setUsuarioDestinatario] = useState<Usuario>();
  const divMensajes = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if(room){
      if(room!=roomAnterior) 
        setMessages([]);
      else
        setRoomAnterior(room);

      const source = new EventSource(`${API_BASE_URL}/chats/stream?roomId=${room.roomId}`);

      source.onmessage = (event) => {
        const newMessage = JSON.parse(event.data) as ChatMessage;
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        setTimeout(()=>{
          scrollToBottom()
        },150)
      };

      return () => {
        source.close();
      };
    }
  }, [room]);

  useEffect(()=>{
    if(room?.destinatarioId!==undefined){
      axios.get(`${API_BASE_URL}/api/v1/users/${room?.destinatarioId}`,
        tokenBearer
      ).then(res =>{
        const user = res.data as Usuario;
        setUsuarioDestinatario(user);
      });
    }
  }, [room])

  const scrollToBottom = () => {
    if (divMensajes.current) {
        divMensajes.current.scrollTop = divMensajes.current.scrollHeight;
    }
  };

  const list = messages.map(
    (row) =><ChatRow key={row.id} item={row} usuarioId={usuario.id} fotoUsuario={usuario?.foto} fotoDestinatario={usuarioDestinatario?.foto} />
  );

  return(
    <div className="col-md-12 p-0 marco-mensajes" style={{height:"76dvh"}}>
      <ul ref={divMensajes} className="chatlist py-2 px-4"  style={{overflowY:'scroll', height:'76dvh'}}>
        {list}
      </ul>
    </div>
  )
};

export default ChatList;