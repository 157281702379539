// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import { createMemoryHistory } from "history";

import Landing from './components/Landing.jsx';
import Login from './components/web/login/Login.tsx';
import Chat from './components/web/Chat/Chat.tsx';
import { Usuario, state } from './components/lib/types.tsx';
import OAuth2RedirectHandler from './oauth2/OAuth2RedirectHandler';
import Compra from './components/web/Compra.tsx';
import NotFound from './components/web/NotFound.js';
import { getCurrentUser } from './components/lib/api.ts';
import Principal from './components/web/principal/Principal.tsx';
import AppHeader from './components/web/parts/AppHeader.jsx';
import AppFooter from './components/web/parts/AppFooter.jsx';
import ProfileUser from './components/web/profile/ProfileUser.tsx';
import LoadingForm from './components/web/loading/Loading.jsx';
import { FRONT_BASE_URL } from './components/lib/constants.js';
import Register from './components/web/login/Register.tsx';

function App() {
  const history = createMemoryHistory();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<Usuario>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(()=>{
    getCurrentUser()
      .then(response => {
        if(currentUser.id==null){
          setCurrentUser(response)
          setAuthenticated(true)
          setLoading(false)
        }
      })
      .catch(error => {
        setLoading(false)
      });
  }, [])

  const handleLogout=()=> {
    localStorage.removeItem("accessToken");
    window.location.href=FRONT_BASE_URL+'/'
    //window.location.href="/"

    //setCurrentUser({})
    //setAuthenticated(false)
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Router>
      <div>
        <LoadingForm cerrar={loading}/>
      </div>
      {!loading?(
        <div>
          <AppHeader authenticated={authenticated} onLogout={handleLogout} usuario={currentUser}/>
            <Routes>
              <Route path="/"  element={<Landing authenticated={authenticated} />}></Route>
              <Route path="/login" element={<Login authenticated={authenticated} />} ></Route>
              <Route path="/register" element={<Register authenticated={authenticated} />} ></Route>
              <Route path="/buy" element={<Compra usuario={currentUser} authenticated={authenticated} />} ></Route>
              <Route path="/profile" element={<ProfileUser usuario={currentUser}  authenticated={authenticated}/>} ></Route>
              <Route path="/main" element={<Principal authenticated={authenticated} usuario={currentUser}/>} ></Route>
              <Route path="/chats" element={<Chat usuario={currentUser} authenticated={authenticated} />} ></Route>
              <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler/>}></Route>
              <Route element={<NotFound/>}></Route>
            </Routes>
          <AppFooter />
          <a href="#" onClick={scrollToTop} className="go-top"><i className="fa fa-angle-up"></i></a>
        </div>
        ):(
          ''
        )
      }
    </Router>
  );
  
}

export default App;