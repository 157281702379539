import React from "react";
import { useEffect, useState } from "react";
//import "./App.css";
import ChatList from "../../chat/ChatList.tsx";
import RoomList from "../../chat/RoomList.tsx";
import { Room } from "../../lib/types.tsx";
import RoomRow from "../../chat/RoomRow.tsx";
import ChatForm from "../../chat/ChatForm.tsx";
import { Navigate } from "react-router";
import './chat.css';


function Chat({usuario, authenticated}) {
  const [room, setRoom] = useState<Room>();
  
  const roomActual = (roomActual:Room) =>{
    setRoom(roomActual);
  }

  const quitarRoomActual=()=>{
    setRoom(undefined)
  }

  setTimeout(()=>{
    const footer=document.getElementById("footer");
    if(footer!==null) footer.style.display='none';

  }, 1000)

  if(!authenticated){
    return (
        <Navigate to="/login"/>
    );
  }
 
  return (
    <div className="col-md-12 m-0 p-0">
      <div className="d-flex">
        <div className={`col-md-3 p-0 ps-2 marco-chat-izquierda ${room?.id ? 'hidden' : ''}`} style={{backgroundColor: "#535bd4"}}>
          <RoomList usuario={usuario} room={room} roomActual={roomActual}/>
        </div>
        <div className="col-md-9 p-0  marco-chat-derecha">
          {/* <div className="col-md-12">
            <RoomRow room={room} onClick={()=>{}}/>
          </div> */}
          <div className="col-md-12 marco-chat-lista" style={{backgroundColor:"#e7e7e7"}}>
            <div className="col-md-12 p-2 marco-boton-regresar-chat" style={{textAlign:'start', background:'#dcdcdc', zIndex: '9999', position: 'relative'}}>
              <a onClick={quitarRoomActual}>
                <i className="fas fa-arrow-circle-left" style={{fontSize:'20px'}}></i>
                <span className="ps-1">Regresar</span>
              </a>
            </div>
            <ChatList room={room} usuario={usuario} />
          </div>
          <div className="col-md-12 marco-chat-form" style={{height:"8dvh", backgroundColor:"#dcdcdc", display: "flex", alignItems:"center"}}>
            <ChatForm room={room} usuarioId={usuario.id}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
